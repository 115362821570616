/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://github.com/tailwindcss/tailwindcss/blob/master/css/preflight.css
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/preflight";
 */
@tailwind preflight;

.fit {
    display: inline-block;
    white-space: nowrap;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.nav {
    background-color: rgba(6, 169, 225, 0);
    transition: .3s background-color ease-out;
}

.nav a {
    transition: .2s color ease-in-out;
}

.transition {
    transition: .2s all ease-in-out;
}

.nav--scrolled {
    background-color: rgba(6, 169, 225, 1);
    transition: .3s background-color ease-in;
}

section:focus {
    outline: none;
}

.hero {
    background-image: url("/_assets/img/banjo.png");
    background-repeat: no-repeat;
    background-color: #06A9E1;
    position: relative;
    background-size: 70%;
    background-position: center 13rem;
    z-index: 0;
    padding-top: 7rem
}

@screen md {
    .hero {
        @apply pt-32;
        background-size: 55%;
        background-position: 9% 160px;
    }

}

@screen lg {
    .hero {
        background-size: 50%;

        background-position: 14% 110px;

    }

}

.main {
    background-image: url("/_assets/img/hills.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
}

/**
  * This injects any component classes registered by plugins.
  *
  * If using `postcss-import`, use this import instead:
  *
  * @import "tailwindcss/components";
  */
@tailwind components;

.button {
    @apply px-6 py-4 flex-no-shrink rounded bg-brand-pink font-sans font-black text-white uppercase text-xl no-underline;
    transition: .2s all ease-in-out;
}

.button:hover {
    @apply bg-white text-brand-pink;
}

.button.bg-brand-green:hover {
    @apply text-brand-green-dark;
}


.text-block a {
    @apply text-white no-underline border-blue border-solid border-b;
}

.text-block p {
    @apply mb-2;

}

.text-block li {
    @apply mb-1;
}

.text-block-tight p {
    @apply mb-1;
}

.text-block-tight a {
    @apply no-underline mb-1 text-white font-sans;
}

.bold-white strong {
    @apply font-semibold text-white;
}

.modal__wrap {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: .2s opacity ease-in;
    pointer-events: none;
    z-index: 50;
}

.modal__wrap--open {
    opacity: 1;
    pointer-events: all;
    transition: .2s opacity ease-out;
}

.modal__bg {
    height: 100vh;
    width: 100vw;
    background-color: rgba(71, 188, 149, 0.8);
    top: 0;
    cursor: pointer;
    left: 0;
}



.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 90%;
    height: 80vh;
    overflow: scroll;
    @apply bg-brand-green-dark p-6 rounded-lg;
}

.js-gallery-modal .modal {
    height: auto;
    overflow: initial;
    max-width: 80%;
    /*height: 45%;*/
}

.slick-slide {
    opacity: 0;
    transition: .4s opacity ease-in-out;
}

.slick-active {
    opacity: 1;
}

@screen lg {
    .js-gallery-modal .modal {}

    .modal {
        height: auto;
        overflow: initial;
    }

}

.slick-slide div {
    text-align: center;
}

.slick-slide img {
    width: auto !important;
    max-height: 80vh;
}

/*
.slick-slider, .slick-list, .slick-track, .slick-slide, .slick-slide div, .slick-slide img {
  height: 100%;
}
.slick-slide div {
  text-align: center;
}
.slick-slide img {
  width: auto !important;
}
*/

.modal .w-full:nth-child(odd) {
    @apply pr-4;
}

.modal .w-full:nth-child(even) {
    @apply pl-4;
}

.modal .w-full:not(:last-of-type) {
    @apply mb-8;
}

.modal-open {
    overflow: hidden;
    height: 100%;
}

.slick-prev::before,
.slick-next::before {
    display: none;
}

.slick-prev {
    left: -50px !important;
}

.slick-next {
    right: -50px !important;
}

.slick-next svg {
    transform: scaleX(-1);
    transform-origin: center;
}

.slick-arrow path {
    transition: .1s fill ease-in-out;
}

.slick-arrow {
    height: 42px !important;
}

.gallery-arrow svg {
    transform: scale(.8);
}

.gallery-arrow.slick-next svg {
    transform: scale(.8) scaleX(-1);
}

/**
  * Here you would add any of your custom component classes; stuff that you'd
  * want loaded *before* the utilities so that the utilities could still
  * override them.
  *
  * Example:
  *
  * .btn { ... }
  * .form-input { ... }
  *
  * Or if using a preprocessor or `postcss-import`:
  *
  * @import "components/buttons";
  * @import "components/forms";
  */

/**
  * This injects all of Tailwind's utility classes, generated based on your
  * config file.
  *
  * If using `postcss-import`, use this import instead:
  *
  * @import "tailwindcss/utilities";
  */
@tailwind utilities;

/**
  * Here you would add any custom utilities you need that don't come out of the
  * box with Tailwind.
  *
  * Example :
  *
  * .bg-pattern-graph-paper { ... }
  * .skew-45 { ... }
  *
  * Or if using a preprocessor or `postcss-import`:
  *
  * @import "utilities/background-patterns";
  * @import "utilities/skew-transforms";
  */